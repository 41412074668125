import * as React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/Hero'

import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { StaticImage } from 'gatsby-plugin-image'

type EgfrExon20Props = {
	location: Location
	data: any
}

const EGFRExon20 = ({ location, data }: EgfrExon20Props) => {
	return (
		<Layout>
			<SEO
				SEOData={{
					title: 'EGFR Exon20 | EXKIVITY® (mobocertinib) for Patients',
					description:
						'Learn about EGFR Exon20 insertion-positive non-small cell lung cancer (NSCLC) for people who have had chemotherapy containing platinum. Please see the Patient Information, Safety, and Boxed Warning.',
					path: location.pathname,
				}}
			/>
			<Hero bgImage={data.heroImage} imageAlt=''>
				<h1 className='text-white'>
					What Are <br className='md:block hidden' />
					EGFR Exon20 Insertions?
				</h1>
			</Hero>
			<div className='grid grid-cols-12 py-10'>
				<div className='col-start-2 col-span-10'>
					<p className='md:text-2xl text-xl font-bold text-pompadourViolet mb-3'>
						A rare mutation, but you&apos;re not alone
					</p>
					<p>
						Your lung cancer is caused by a rare gene mutation called an{' '}
						<span className='text-mediumVioletRed font-bold'>
							EGFR Exon20 insertion.
						</span>{' '}
						This epidermal growth factor receptor (EGFR) mutation occurs in about{' '}
						<span className='text-mediumVioletRed font-bold'>2%</span> of people with
						non-small cell lung cancer, or NSCLC.
					</p>
				</div>
				<div className='col-start-2 col-span-10 text-center'>
					<h2 className='md:text-2xl text-xl font-bold text-pompadourViolet mb-2 mt-10'>
						What is NSCLC?
					</h2>
					<p className='text-mediumVioletRed font-bold'>
						There are 2 main types of lung cancer:
					</p>
				</div>
				<div className='md:col-start-3 col-start-2 md:col-span-8 col-span-10 mt-10'>
					<div className='md:grid md:grid-cols-12 text-center md:divide-x md:divide-y-0 divide-y divide-lightGrey md:divide-x-4 divide-y-4'>
						<div className='md:col-span-6 md:pb-0 pb-8'>
							<StaticImage
								src='../images/egfr-exon20/lungs-pink.png'
								className='w-3/12 mx-auto'
								alt=''
							/>
							<p className='text-mediumVioletRed font-bold mt-5'>
								Non-small cell lung cancer (NSCLC)
							</p>
						</div>
						<div className='sm:col-span-6 sm:pt-0 pt-8'>
							<StaticImage
								src='../images/egfr-exon20/lungs-green.png'
								className='w-3/12 mx-auto'
								alt=''
							/>
							<p className='font-bold mt-5'>Small cell lung cancer (SCLC)</p>
						</div>
					</div>
				</div>
				<div className='col-start-3 col-span-8 mt-10 text-center text-mediumVioletRed'>
					Most people with lung cancer (about 8 in 10) have NSCLC,{' '}
					<strong>the same type as you</strong>
				</div>
				<div className='col-span-12 bg-gradient-to-r from-white to-lightGrey py-10 mt-14'>
					<div className='sm:grid sm:grid-cols-12'>
						<div className='col-start-2 col-span-3'>
							<StaticImage
								src='../images/egfr-exon20/body.png'
								className='w-4/12 mx-auto'
								alt=''
								layout='fullWidth'
							/>
						</div>
						<div className='col-span-7 sm:px-0 px-7'>
							<p className='font-bold sm:text-5xl text-4xl text-mediumVioletRed tracking-wide sm:pt-0 pt-3 sm:text-left text-center'>
								AROUND 2,000-4,000 PEOPLE
							</p>
							<p className='sm:text-2xl text-xl text-mediumVioletRed sm:mt-5 tracking-wide sm:text-left text-center'>
								are diagnosed with NSCLC caused by an <strong>EGFR Exon20</strong>{' '}
								insertion mutation <strong>each year</strong> in the US.
							</p>
							<p className='mt-5 sm:text-left text-center'>
								Identifying your particular gene mutation can help you and your doctor
								choose specific treatment options—or <i>targeted therapies</i>—that are
								more likely to effectively treat your disease.
							</p>
						</div>
					</div>
				</div>
				<div className='col-start-2 col-span-10 py-10 mt-10'>
					<p className='sm:text-2xl text-xl font-bold text-pompadourViolet mb-3'>
						Treating EGFR Exon20 insertion mutations
					</p>
					<p>
						The treatment of EGFR Exon20 insertion mutations is advancing and more
						treatment options are now available. <br className='hidden lg:block' />{' '}
						<strong>This is a time of hope.</strong>
					</p>

					<div className='table mx-auto sm:p-10 p-7 text-center text-mediumVioletRed font-bold bg-weakYellow tracking-wide mt-10'>
						<p>
							EXKIVITY<sup>&#174;</sup> (mobocertinib) is helping to change the
							treatment story for people like you.
						</p>
					</div>
				</div>
			</div>
		</Layout>
	)
}
export default EGFRExon20

export const query = graphql`
	{
		heroImage: file(relativePath: { eq: "egfr-exon20/hero-egfr-exon20.png" }) {
			...heroImage
		}
	}
`
